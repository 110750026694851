abbr[title] {
  border-bottom: multiply-two-numbers($size, 0.1) dotted
    map-deep-get($colours, black, "primary") !important;
  cursor: help !important;
  text-decoration: none !important;
  position: relative;

	@media print
	{
		border-bottom: none;

		&::after
		{
			content: "";
		}
	}
}

@media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
  abbr[title]:hover::after,
  abbr[title]:focus::after {
    content: attr(title);

    /* position tooltip like the native one */
    position: absolute;
    right: 0;
    top: 10px;
    white-space: nowrap;
    max-width: unset !important;

    /* style tooltip */
    vertical-align: middle;
    height: auto !important;
    width: auto !important;
    line-height: 1 !important;
    color: map-deep-get($colours, white, "primary");
    border-radius: 4px;
    margin: 14px 0px;
    padding: 8px;
    text-overflow: ellipsis;
    background: rgba(97, 97, 97, 0.9);
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }

  abbr[title].pre-wrap:hover::after,
  abbr[title].pre-wrap:focus::after {
    white-space: pre-wrap;
  }

  abbr[title].absolute-left:hover::after,
  abbr[title].absolute-left:focus::after {
    right: unset;
    left: 0;
  }
}