@use "sass:map";

.font-weight {
  &--normal {
    font-weight: map.get($font-weights, "normal");
  }

  &--medium {
    font-weight: map.get($font-weights, "medium");
  }

  &--bold {
    font-weight: map.get($font-weights, "bold");
  }
}

.display {
  &--inline-block {
    display: inline-block;

    &.--important {
      display: inline-block !important;
    }
  }

  &--inline {
    display: inline;
  }

  &--block {
    display: block;
  }

  &--none {
    display: none;
  }
}

.print {
  &--hide {
    @media print {
      display: none !important;
    }
  }

  &--show {
    display: none !important;

    @media print {
      display: block !important;
    }
  }
}

.clear {
  clear: both;
}

.text-align {
  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }
}

.margin--center {
  margin: 0 auto;
}

.background-colour {
  &--grey-primary {
    background-color: map-deep-get($colours, grey, "primary") !important;
  }

  &--grey-secondary {
    background-color: map-deep-get($colours, grey, "secondary") !important;
  }
}

.grey-side-bar {
  border-left: $size solid map-deep-get($colours, gray, "secondary");
  padding-left: $size;
}

.word-break--break-word {
  word-break: break-word;
}

.white-space {
  &--pre-wrap {
    white-space: pre-wrap;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--wrap {
    white-space: normal !important;
  }
}

.float {
  &--right {
    float: right;
  }

  &--left {
    float: left;
  }
}

.colour {
  &--red-primary {
    @include text-colour(red, "primary");
  }

  &--green-primary {
    @include text-colour(green, "primary");
  }

  &--green-index {
    @include text-colour(green, "index");
  }

  &--yellow-primary {
    @include text-colour(yellow, "primary");
  }

  &--orange-primary {
    @include text-colour(orange, "primary");
  }

  &--blue-primary {
    @include text-colour(blue, "primary");
  }

  &--gray-tertiary {
    @include text-colour(gray, "tertiary");
  }
}

.vertical-align--top {
  vertical-align: top;
}

.gray-side-bar {
  border-left-width: $size;
  border-left-style: solid;
  border-left-color: map-deep-get($colours, grey, "secondary");
  padding-left: $size;

  @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
    padding-left: divide-two-numbers($size, 2);
  }
}

.border--black-primary {
  border: multiply-two-numbers($size, 0.1) solid
    map-deep-get($colours, black, "primary");
  border-radius: divide-two-numbers($size, 2);
}

#gradient-print {
  @extend %gradiant-index;

  margin-left: 0.5em !important;
}

[data-gradient] {
  @extend %gradiant-index;
}

[data-dial],
#dial-print {
  left: 25%;
  z-index: 22;
  float: left;
  height: multiply-two-numbers($size, 1.2);
  width: multiply-two-numbers($size, 0.2);
  position: relative;
  opacity: 0.7;
  background-color: map-deep-get($colours, black, "primary");
}

[data-dial-text],
#dial-text-print {
  font-weight: map.get($font-weights, "medium");
  text-align: center;
  width: 100%;
}

strong {
  font-weight: map.get($font-weights, "medium");
}

abbr {
  cursor: help;
}

//Percentage widths
@for $i from 1 through 100 {
  .width--#{$i}-percent {
    @include width(#{$i} + "%");
  }

  .width--#{$i}-percent-important {
    @include width(#{$i} + "%", true);
  }
}

// Margin & Padding
@each $space in $spaceamounts {
  // All around margin
  .m-#{$space},
  .margin--#{$space}rem {
    margin: #{$space}rem !important;
  }

  // Vertical Margin
  .my-#{$space},
  .margin-vertical--#{$space}rem {
    margin: #{$space}rem 0 !important;
  }

  // Margin top
  .mt-#{$space},
  .margin-top--#{$space}rem {
    margin-top: #{$space}rem !important;
  }

  // Margin right
  .mr-#{$space},
  .margin-right--#{$space}rem {
    margin-right: #{$space}rem !important;
  }

  // Margin bottom
  .mb-#{$space},
  .margin-bottom--#{$space}rem {
    margin-bottom: #{$space}rem !important;
  }

  // Margin left
  .ml-#{$space},
  .margin-left--#{$space}rem {
    margin-left: #{$space}rem !important;
  }

  // All around Padding
  .p-#{$space},
  .padding--#{$space}rem {
    padding: #{$space}rem !important;
  }

  // Horizontal Padding
  .py-#{$space},
  .padding-horizontal--#{$space}rem {
    padding: #{$space}rem 0 !important;
  }

  // Vertical padding
  .padding-vertical--#{$space}rem {
    padding: 0 #{$space}rem !important;
  }

  // Padding top
  .pl-#{$space},
  .padding-left--#{$space}rem {
    padding-left: #{$space}rem !important;
  }

  // Padding right
  .pr-#{$space},
  .padding-right--#{$space}rem {
    padding-right: #{$space}rem !important;
  }

  // Padding bottom
  .pb-#{$space},
  .padding-bottom--#{$space}rem {
    padding-bottom: #{$space}rem !important;
  }

  // Padding left
  .pl-#{$space},
  .padding-left--#{$space}rem {
    padding-left: #{$space}rem !important;
  }
}
