.flex //Default class needed for every parent container needing a flex layout
{
	@extend %flex; //Attach flex placeholder styles

	@media(max-width: map-deep-get($breakpoints, "mobile", "max")) //Styling for the "mobile" breakpoint
	{
		margin-bottom: divide-two-numbers($size, 2);
	}

	&.flex
	{
		&--cols
		{
			&-none //Creates a columnless flex layout; must be used with the "flex" class
			{
				> .flex-child //A child of element with "flex flex--cols-none" classes
				{
					width: auto;
					margin-right: $size;

					&:only-child
					{
						margin-right: 0;
					}

					&.flex-child--flex-placeholder //A child of element with "flex flex--cols-none" classes; must be used with the"flex-child" class
					{
						&::before
						{
							@extend %css-error-message;

							content: "There is no need to have 'flex-child--flex-placeholder elements when using an flex container with class 'flex--cols-none'";
						}
					}
				}
			}

			&-2 //Creates a 2 column flex layout; must be used with the "flex" class
			{
				> .flex-child:not(.flex-child--full-width):not(.flex-child--side-bar):not(button) //A child of element with "flex flex--cols-2" classes; use class when it isn't a button and also when it doesn't contain the class "flex-child--full-width"
				{
					@include flex--cols-2-and-print(2);

					@media(max-width: map-deep-get($breakpoints, "mobile", "max")) //Styling for the "mobile" breakpoint
					{
						&:not(.flex-child--flex-grow):not(.flex-child--flex-shrink)
						{
							width: 100%;

							&:is(.fieldset)
							{
								width: 100% !important;
							}

							&:nth-child(odd) //Every odd element containing "flex-child" class
							{
								padding-right: 0;
							}

							&:nth-child(even) //Every even element containing "flex-child" class
							{
								padding-left: 0;
							}

							&.flex-child--important //A child element with "flex flex--cols-2 flex-child" classes
							{
								width: 100% !important;
							}

							&.flex-child--flex-placeholder //Used in conjunction with "flex-child" class; stops showing the "flex-child--flex-placeholder" element
							{
								display: none;
							}
						}
					}

					@media print
					{
						@include flex--cols-2-and-print(2);
					}
				}

				&-flag
				{
					> .flex-child:not(.flex-child--full-width):not(.flex-child--side-bar):not(button) //A child of element with "flex flex--cols-2" classes; use class when it isn't a button and also when it doesn't contain the class "flex-child--full-width"
					{
						&:first-child
						{
							padding-right: 0;
						}

						&:last-child
						{
							padding-left: $size;

							&:is(app-flag)
							{
								padding-left: 0;

								.button
								{
									margin-left: $size !important;
								}
							}
						}

						&.flex-child--flex-grow
						{
							padding-left: 0;
							padding-right: 0;
						}

						.button
						{
							padding: 0;
							margin: 0;
							min-width: 0;
							width: auto;
						}
					}
				}

				&.mat-expansion-panel-header-title
				{
					margin-bottom: 0;
				}
			}

			&-3 //Creates a 3 column flex layout; must be used with the "flex" class
			{
				> .flex-child //A child of element with "flex flex--cols-3" classes
				{
					@include flex--cols-3-and-print(3);

					@media(max-width: map-deep-get($breakpoints, "tablet", "max")) //Styling for the "tablet" breakpoint
					{
						@include flex-child(2); //Mixin creates 2 column layout

						&.flex-child--flex-placeholder //Used in conjunction with "flex-child" class; stops showing the "flex-child--flex-placeholder" element
						{
							display: none;
						}

						&:nth-child(odd)
						{
							padding-left: 0;
							padding-right: divide-two-numbers($size, 2);
						}

						&:nth-child(even)
						{
							padding-left:divide-two-numbers($size, 2);
							padding-right: 0;
						}
					}

					@media(max-width: map-deep-get($breakpoints, "mobile", "max")) //Styling for the "mobile" breakpoint
					{
						width: 100%;
						padding-left: 0 !important;
						padding-right: 0 !important;
					}

					@media print
					{
						@include flex--cols-3-and-print(3);
					}
				}
			}

			&-4 //Creates a 3 column flex layout; must be used with the "flex" class
			{
				> .flex-child
				{
					@include flex-child(4);

					&:nth-child(4n + 1)
					{
						padding-left: 0;
						padding-right: divide-two-numbers($size, 2);
					}

					&:nth-child(4n + 2),
					&:nth-child(4n + 3)
					{
						padding-left: divide-two-numbers($size, 2);
						padding-right: divide-two-numbers($size, 2);
					}

					&:nth-child(4n + 4)
					{
						padding-left: divide-two-numbers($size, 2);
						padding-right: 0;
					}

					@media(max-width: map-deep-get($breakpoints, "tablet", "max"))
					{
						@include flex-child(2);

						&.flex-child--flex-placeholder
						{
							display: none;
						}

						&:nth-child(odd)
						{
							padding-left: 0;
							padding-right: divide-two-numbers($size, 2);
						}

						&:nth-child(even)
						{
							padding-left:divide-two-numbers($size, 2);
							padding-right: 0;
						}
					}

					@media(max-width: map-deep-get($breakpoints, "mobile", "max"))
					{
						width: 100%;
						padding-left: 0 !important;
						padding-right: 0 !important;
					}

					@media print
					{
						@include flex-child(4);
					}
				}
			}

			&-5 //Creates a 5 column flex layout; must be used with the "flex" class
			{
				> .flex-child //A child of element with "flex flex--cols-5" classes
				{
					@include flex-child(5); //Mixin creates 5 column layout

					padding-left: divide-two-numbers($size, 2);
					padding-right: divide-two-numbers($size, 2);

					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5)
					{
						margin-top: 0;
					}

					&:nth-child(5n + 1)
					{
						padding-left: 0;
					}

					&:nth-child(5n + 5)
					{
						padding-right: 0;
					}

					&:nth-last-child(5),
					&:nth-last-child(4),
					&:nth-last-child(3),
					&:nth-last-child(2),
					&:nth-last-child(1)
					{
						margin-bottom: 0;
					}

					@media(max-width: map-deep-get($breakpoints, "tablet", "max")) //Styling for the "tablet" breakpoint
					{
						@include flex--cols-5-tablet-and-print(3);
					}

					@media(max-width: map-deep-get($breakpoints, "mobile", "max")) //Styling for the "mobile" breakpoint
					{
						padding-left: 0 !important;
						padding-right: 0 !important;
						margin-bottom: $size !important;
						width: 100%;

						&:last-child
						{
							margin-bottom: 0 !important;
						}
					}

					@media print
					{
						@include flex--cols-5-tablet-and-print(3);
					}
				}
			}
		}

		&--full-height //Modifies flex layout that it stretches full height of the content; must be used with the "flex" class
		{
			align-items: stretch;
		}

		&--center-horizontal //Modifies a flex layout such that it horizontally aligns flex-child elements to the center
		{
			justify-content: center;
		}

		&--end-vertical  //Modifies a flex layout such that it puts all flex-child elements to the right of the container (default is left of the container)
		{
			justify-content: flex-end;
		}

		&--baseline-vertical //Modifies a flex layout such that it aligns all flex-child elements such that their text is aligned, but not necessairly the flex-child element itself
		{
			align-items: baseline;
		}

		&--center-vertical //Modifies a flex layout such that it puts all flex-child elements vertically in the middle; each middle point of flex-child elements will align
		{
			align-items: center;
		}

		&--top-align-vertical //Modifies a flex layout such that it puts all flex-child elements vertically in the top
		{
			align-items: flex-start;
		}

		&--nowrap //Modifies a flex layout such that it will not allow flex-child elements to wrap to another row
		{
			flex-wrap: nowrap;
		}

		&--wrap //Modifies a flex layout such that it will allow flex-child elements to wrap to another row
		{
			flex-wrap: wrap;
		}

		&--justify-content-space-evenly //Modifies a flex layout such that it will have the flex-child elements spaced evenly
		{
			justify-content: space-evenly;
		}
	}

	.mat-expansion-panel-body
	{
		padding-top: 0;
		padding-right: $size;
		padding-left: $size;
		padding-bottom: $size;

		@media(max-width: map-deep-get($breakpoints, "mobile", "max")) //Styling for the "mobile" breakpoint
		{
			padding-left: divide-two-numbers($size, 2);
			padding-right: divide-two-numbers($size, 2);
		}
	}
}

.report
{
	.flex.background-colour--grey-primary
	{
		padding: $size;
	}
}