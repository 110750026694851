.flex-child //Default styling for "flex-child elements; always child of "flex" elements
{
  &.flex-child {
    &--flex-grow //A "flex-child" meant to grow to use up remainder of available row space
    {
      flex-grow: 1;
    }

    &--flex-shrink //A "flex-child meant to shrink to allow other elements to grow
    {
      flex-shrink: 1;
      width: auto !important;
    }

    &--side-bar //A "flex-child" meant to be used as a side bar (usually containing the summary and an index rating)
    {
      max-width: map-deep-get($breakpoints, "mobile", "min");
      width: 100%;
      background-color: map-deep-get($colours, grey, "primary");
      padding: $size;
      margin-left: divide-two-numbers($size, 2);

      .flex.flex--cols-2 {
        justify-content: flex-end;
      }

      @media (max-width: map-deep-get($breakpoints, "tablet", "max")) {
        max-width: 100%;
        margin-left: 0;
      }

      @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
        padding: divide-two-numbers($size, 2);

        &.flex.flex--cols-2,
        section.flex.flex--cols-2 {
          > .flex-child:not(.flex-child--full-width) {
            width: 50%;
          }
        }
      }

      @media print {
        max-width: 100%;
        margin-left: 0;
      }
    }

    &--full-width //A "flex-child" that uses the full available width
    {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      &.hr //A "flex-child" that is a hr element
      {
        padding: 0;
        margin-left: $size;
        margin-right: $size;
      }
    }

    &--align-top //A "flex-child" that aligns itself to the top of the available space
    {
      align-self: flex-start;

      mat-form-field,
      .mat-mdc-form-field,
      mat-form-field.mat-form-field {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-subscript-wrapper {
          padding: 0;
          margin: 0;
        }
      }
    }

    &--align-bottom //A "flex-child" that aligns itself to the bottom of the available space
    {
      align-self: flex-end;
    }

    &--align-center //A "flex-child" that aligns itself to the center of the available space
    {
      align-self: center;
    }

    &--left-child {
      padding-left: 0 !important;
      padding-right: divide-two-numbers($size, 2) !important;
    }

    &--middle-child {
      padding-left: divide-two-numbers($size, 2) !important;
      padding-right: divide-two-numbers($size, 2) !important;
    }

    &--right-child {
      padding-left: divide-two-numbers($size, 2) !important;
      padding-right: 0 !important;
    }
  }

  .mat-mdc-form-field //Material child element of "flex-child"
  {
    width: 100%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  mat-checkbox.mat-mdc-checkbox {
    margin-top: multiply-two-numbers($size, 0.25);
    margin-bottom: $size;
    margin-left: 0;
    margin-right: 0;
  }
}
