button.button //Default styling for buttons
{
  margin-top: 0;
  margin-right: divide-two-numbers($size, 2);
  margin-left: divide-two-numbers($size, 2);
  margin-bottom: $size;
  width: auto !important;
  border-radius: multiply-two-numbers($size, 0.3);
  cursor: pointer;

  &.button--green-primary //Green primary button style; requires class "button"
  {
    @include button--primary(green, "primary");

    &:focus,
    &:hover {
      @include button--hover-focus(green, "primary");
    }

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--green-secondary //Green secondary button style; requires class "button"
  {
    @include button--secondary(green, "primary");

    &:focus,
    &:hover {
      @include button--hover-focus(green, "primary");
    }

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--green-tertiary //Green tertiary button style; requires class "button"
  {
    @include button--tertiary(green, "primary");

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--yellow-tertiary //Yellow tertiary button style; requires class "button"
  {
    @include button--tertiary(yellow, "primary");

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--blue-tertiary //Blue tertiary button style; requires class "button"
  {
    @include button--tertiary(blue, "primary");

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--orange-tertiary //Orange tertiary button style; requires class "button"
  {
    @include button--tertiary(orange, "primary");

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--red-primary //Red primary button style; requires class "button"
  {
    @include button--primary(red, "primary");

    &:focus,
    &:hover {
      @include button--hover-focus(red, "primary");
    }

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--red-secondary //Red secondary button style; requires class "button"
  {
    @include button--secondary(red, "primary");

    &:focus,
    &:hover {
      @include button--hover-focus(red, "primary");
    }

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--red-tertiary //Green tertiary button style; requires class "button"
  {
    @include button--tertiary(red, "primary");

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--black-primary //Black primary button style; requires class "button"
  {
    @include button--primary(black, "primary");

    &:focus,
    &:hover {
      @include button--hover-focus(black, "primary");
    }

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--black-secondary //Black secondary button style; requires class "button"
  {
    @include button--secondary(black, "primary");

    &:focus,
    &:hover {
      @include button--hover-focus(black, "primary");
    }

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--black-tertiary //Black tertiary button style; requires class "button"
  {
    @include button--tertiary(black, "primary");

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--white-tertiary //White tertiary buttons style; requires class "button"
  {
    @include button--tertiary(white, "primary");

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &.button--transparent-primary //Transparent button style; requires class "button"
  {
    @include button--primary(transparent, "primary", gray, "tertiary");

    &:focus,
    &:hover {
      @include button--hover-focus(green, "primary");
    }

    &:disabled,
    &[aria-disabled="true"] {
      @extend %disabled;
    }
  }

  &:focus {
    outline: divide-two-numbers($size, 4) solid
      map-deep-get($colours, blue, "highlight-secondary") !important;
  }

  &.button--print {
    margin-top: negate-numbermultiply-two-numbers($size, 4.1);
    float: right;
  }

  > mat-icon.mat-icon {
    margin-right: 0;
    color: inherit;
  }

  &.flex-child:not(.flex-child--button-defaults) {
    margin: 0;
    padding: 0;
    align-self: center;
  }

  mat-icon.mat-icon {
    width: auto;
    height: auto;
    margin-top: 0;
    cursor: inherit;
  }
}

// Fix alignment of mat-checkboxs with tooltip button
.mat-checkbox-with-tooltip {
  .mdc-label {
    button {
      margin: 0 8px !important;
      padding-bottom: 0 !important;
    }
  }
}

span.button
{
	&--accessibility-text
	{
		color: transparent;
		display: block;
		position: absolute;
		width: multiply-two-numbers($size, 2);
		height: multiply-two-numbers($size, 2);
	}
}