@use "base";

body {
  h1.h1 {
    @extend %header; //Common header style

    &--page-title {
      @include header(1.25, 1.25, 1.25, 0, 0); //Heading styles mixin

      font-style: italic;
    }

    &--component-title {
      @include header(1, 0, 1, 0, 0); //Heading styles mixin
    }
  }

  h2.h2,
  h3.h3,
  h4.h4,
  h5.h5,
  h6.h6 {
    @extend %header; //Common header style

    &--underlined-heading {
      @extend %header--border; //Common header with border style mixin
    }

    &--component-title {
      @include header(1, 0, 1, 0, 0); //Heading styles mixin
    }
  }

  .flex-child--side-bar {
    h2.h2,
    h3.h3,
    h4.h4,
    h5.h5,
    h6.h6 {
      border-bottom: base.map-deep-get(base.$colours, black, "primary")
        base.multiply-two-numbers(base.$size, 0.1) solid;
    }
  }

  h2.h2 {
    &--calculator-title {
      @include header(1.25, 1.25, 0, 0.5, 0.5); //Heading styles mixin

      width: auto;
    }
  }

  p.p.p--expansion-panel-summary-title {
    @include header(1.125, 0, 1, 0, 0); //Heading styles mixin
  }

  .report //Heading styles for reports
  {
    h2.h2,
    h3.h3,
    h4.h4,
    h5.h5,
    h6.h6 {
      &--sub-section {
        border: none;
        margin-bottom: 0;
      }
    }
  }
}
