fieldset.fieldset {
  border-radius: multiply-two-numbers($size, 0.3);
  border: multiply-two-numbers($size, 0.1) solid
    map-deep-get($colours, gray, "secondary");
  margin-bottom: $size;
  padding-left: $size !important;
  padding-right: $size !important;
  padding-top: $size;

  &:hover {
    box-shadow: 0 0 0 multiply-two-numbers($size, 0.1)
      map-deep-get($colours, black, "primary");
    transition: box-shadow 0.1s linear;
  }

  &:focus,
  &:focus-within {
    box-shadow: 0 0 0 multiply-two-numbers($size, 0.1)
      map-deep-get($colours, green, "primary");
    transition: box-shadow 0.1s linear;
  }

  legend {
    font-size: multiply-two-numbers($size, 0.8);
    padding-left: multiply-two-numbers($size, 0.3);
    padding-right: multiply-two-numbers($size, 0.3);
    margin-left: negate-number(divide-two-numbers($size, 2));
    color: map-deep-get($colours, gray, "tertiary");
    background-color: map-deep-get($colours, white, "primary");
  }

  &.flex-child {
    width: 49.5% !important;

    &.flex-child--full-width {
      width: 99.9% !important;
      padding-left: $size !important;
      padding-right: $size !important;
      margin-left: multiply-two-numbers($size, 0.1);

      @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
        padding-left: divide-two-numbers($size, 2) !important;
        padding-right: divide-two-numbers($size, 2) !important;
      }
    }
  }
}
