div,
section,
article {
  max-width: 100%;
  span.mdc-tab__text-label //Changed opacity of tabs for AODA
  {
    color: map-deep-get($colours, green, "primary") !important;
    font-size: $size;
    letter-spacing: normal;
  }

  &.mdc-tab.mdc-tab-indicator--active //Change colour of tabs for AODA
  {
    span.mdc-tab__text-label {
      color: map-deep-get($colours, black, "primary") !important;
    }
  }

  &.mat-drawer-backdrop {
    position: fixed;
  }

  &.background-colour--grey-primary,
  &.flex-child.background-colour--grey-primary {
    padding-left: $size !important;
    padding-right: $size !important;

    p {
      @at-root div.background-colour--grey-primary {
        padding-top: multiply-two-numbers($size, 0.72);
        padding-bottom: 0;
      }
    }
  }

  &#calculatorHeader {
    @extend %sticky; //Make it sticky using placeholder

    background-color: map-deep-get($colours, white, "primary");
    z-index: 3;

    .flex {
      &.flex--cols-2 {
        flex-wrap: nowrap;

        > .flex-child:not(.flex-child--full-width):not(button) {
          @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
            width: auto;
          }

          &:first-child {
            @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
              width: calc(100% - #{multiply-two-numbers($size, 7)});
            }

            > div {
              text-overflow: ellipsis;

              @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
                max-width: calc(100vw - #{multiply-two-numbers($size, 12)});
                overflow-x: hidden;
              }

              @media (max-width: map-deep-get($breakpoints, "mobile", "min")) {
                max-width: multiply-two-numbers($size, 11);
              }
            }
          }
        }
      }
    }
  }
}
