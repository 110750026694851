/* You can add global styles to this file, and also import other style files */
//@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined");
@import "./assets/styles/utils/bootstrap.css";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "main";

$flag-image-size: multiply-two-numbers($size, 2);

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: map-deep-get($colours, black, "primary");
  overflow-x: hidden;
  height: 100%;
  min-width: map-deep-get($breakpoints, "mobile", "min");
  max-width: map-deep-get($breakpoints, "desktop", "max");

  @media (min-width: map-deep-get($breakpoints, "desktop", "max")) {
    margin: 0 auto !important;
    box-shadow: 0 0 $size map-deep-get($colours, gray, "tertiary");
  }

  @media (max-width: map-deep-get($breakpoints, "mobile", "min")) {
    overflow-x: visible;
  }

  main {
    position: relative;
    z-index: 1;
  }
}

a {
  text-decoration: none;
  color: map-deep-get($colours, green, "index") !important;

  .mdc-list-item__primary-text {
    color: map-deep-get($colours, green, "primary") !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  color: map-deep-get($colours, black, "primary") !important;
  opacity: 0.5;
  cursor: not-allowed !important;

  *
  {
	cursor: inherit !important;
  }
}

.padded-form {
  padding: 0 1em 0 1em;
}

.left-padded-form {
  padding: 0 0 0 1em;
}

.no-padding-horizontal {
  padding-left: 0;
  padding-right: 0;
}

.main-content {
  display: flex;
  flex-flow: column;
  height: 100%;
  // margin-bottom: $footer-height;

  .calculator-name-form {
    margin-top: 1.4em;
  }

  .clear-toolbar {
    background: transparent;
  }

  // .mat-toolbar-row {
  //   white-space: normal !important;
  //   word-break: break-all !important;
  //   height: auto !important;
  // }

  .main-header {
    .main-title {
      margin: 0.5em 0 0 1.1em;
      font-size: multiply-two-numbers($size, 1.375);
    }
  }

  .toolbar-primary-icon {
    color: map-deep-get($colours, green, "primary");
    margin: 0.4em 0.5em 0 0;
    i {
      font-size: multiply-two-numbers($size, 1.125);
    }
  }

  .form-buttons {
    margin-right: 1em !important;
    button {
      float: right;
      margin-left: 1em;
    }
  }

  .toolbar-buttons {
    color: map-deep-get($colours, green, "primary");
  }

  .main-toolbar {
    .toolbar-title {
      text-transform: uppercase;
    }
  }

  .padded-content {
    padding: 0 1.5em 3em 1.5em;
  }

  .title-divider {
    margin-top: -1em;
    margin-bottom: 2em;
  }

  /* fix 150% issue #2271 */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 0.25em;
  }

  .mat-mdc-checkbox {
    margin-right: 2em;
    margin-bottom: 2em;
  }

  .row {
    margin: 1em 0;
  }

  .sub-section {
    margin: 0 1em 1em 1em;
    padding: 0.5em 0 0.5em 0;
    background-color: map-deep-get($colours, white, "primary");
  }

  .icon-button {
    i {
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      margin-left: 0.5em;
    }
  }

  .slider-hint {
    margin: -1.5em 2em 0 1em;
    span {
      font-family: Roboto, "Helvetica Neue", sans-serif;
      color: map-deep-get($colours, black, "transparent");
      font-weight: 400;
      line-height: 1.125;
      font-size: multiply-two-numbers($size, 0.75);
      vertical-align: top;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  .mat-slider-horizontal {
    width: 100%;
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-wrapper {
      margin-right: 2em !important;
    }
  }
}

.mat-suffix {
  font-size: multiply-two-numbers($size, 0.75);
  color: map-deep-get($colours, black, "transparent");
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label,
.mat-mdc-tab-link {
  color: map-deep-get($colours, green, "primary");
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-accent .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: map-deep-get($colours, green, "primary");
}

.printLayout {
  display: none;
  width: 600px;
  table {
    width: 100%;
  }
}

.screnPrintLayout {
  display: block;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .printLayout {
    display: block;
    width: 600px;
  }

  .printScreenLayout {
    display: block;
    width: 960px;
    table {
      width: 100%;
    }
  }
  .print-footer {
    width: 960px;
    background-color: map-deep-get($colours, white, "primary") white;
    padding-bottom: 2em;
  }

  // hide sys header for printing
  .OSAutoMarginTop {
    display: none;
  }

  .display--block {
    display: none;
  }

  .mat-mdc-tooltip-panel {
    display: none !important;
  }

  mat-tab-header {
    display: none !important;
  }
}

/*
* Remove spinner on input of number
* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
* Remove spinner on input of number
* Webkit browsers like Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/*Wrap mat-checkbox label*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-layout {
  white-space: normal !important;
}

/* Keyboard active focus blue outline for AODA*/
.cdk-keyboard-focused {
  outline: -webkit-focus-ring-color auto 5px !important;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.hide {
  display: none !important;
}

.summary-container {
  width: 100%;
}

.profile-details-wrapper {
  display: table-row;
}

.profile-details {
  display: inline-block;
  vertical-align: bottom;
}

.notes {
  white-space: pre-wrap;
}

.tab-section {
  margin: 1em 1em 0.5em 0.5em !important;
}

.spinner {
  margin: 10px 10px 10px 10px;
}

.no-padding {
  padding: 0 !important;
}

.radio-group-column {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.radio-group-row {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}

.no-wrap {
  white-space: nowrap;
}

.inline {
  display: inline;
}

.date-picker-step {
  .mat-mdc-form-field-icon-suffix {
    display: inline-flex !important;
    align-items: center !important;
    flex: none !important;
    white-space: nowrap !important;
  }
}

mat-form-field.mat-form-field-invalid {
  --mdc-outlined-text-field-outline-width: 2px;
  --mdc-outlined-text-field-error-label-text-color: map-deep-get(
    $colours,
    gray,
    "tertiary"
  );
  --mdc-outlined-text-field-error-hover-label-text-color: map-deep-get(
    $colours,
    gray,
    "tertiary"
  );
}
