@use "sass:map";

@mixin table-header {
  background-color: map-deep-get($colours, gray, "primary");
  font-size: $size;
  color: map-deep-get($colours, black, "primary");
  line-height: $size;
  border-top: multiply-two-numbers($size, 0.1) solid
    map-deep-get($colours, gray, "secondary");
  border-bottom: multiply-two-numbers($size, 0.1) solid
    map-deep-get($colours, gray, "secondary");
}

table {
  &.table,
  &.table.mat-mdc-table {
    margin-bottom: $size;
    width: 100%;
    border-collapse: collapse;
    color: map-deep-get($colours, black, "primary");

    caption {
      color: map-deep-get($colours, white, "primary");
      background-color: map-deep-get($colours, gray, "tertiary");
      text-align: left;
      padding: $size;
      font-weight: map.get($font-weights, "bold");
      display: table-caption;
    }

    thead tr td {
      @include table-header;
    }

    tr {
      &.mat-mdc-header-row {
        height: auto;
      }

      th,
      th.mat-mdc-header-cell {
        @include table-header;
        padding: multiply-two-numbers($size, 0.75);
        font-weight: map.get($font-weights, "medium");
        vertical-align: middle;

        &:first-child {
          padding-left: $size;

          @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
            padding-left: divide-two-numbers($size, 2);
          }
        }

        &:last-child {
          padding-right: $size;

          @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
            padding-right: divide-two-numbers($size, 2);
          }
        }

        button.button {
          margin: 0;
        }
      }

      td,
      td.mat-mdc-cell {
        font-size: $size;
        color: map-deep-get($colours, black, "primary");
        line-height: $size;
        border-bottom: multiply-two-numbers($size, 0.1) solid
          map-deep-get($colours, gray, "secondary");
        background-color: map-deep-get($colours, white, "primary");
        padding: multiply-two-numbers($size, 0.75);

        &:first-child {
          padding-left: $size;

          @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
            padding-left: divide-two-numbers($size, 2);
          }
        }

        &:last-child {
          padding-right: $size;

          @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
            padding-right: divide-two-numbers($size, 2);
          }
        }

        p {
          &:first-child {
            @at-root td {
              padding-top: $size !important;
              // padding-top: 0 !important;
            }
          }
        }
      }
    }

    @media print {
      &--no-print-split {
        page-break-inside: avoid;

        thead,
        tbody,
        tfoot,
        tr,
        td,
        th {
          page-break-inside: avoid;
        }
      }
    }
  }

  &.table.mat-mdc-table {
    width: 99.65%;
    margin: 0 auto;

    tr {
      th,
      th.mat-mdc-header-cell,
      td,
      td.mat-mdc-cell {
        border-bottom: multiply-two-numbers($size, 0.15) solid
          map-deep-get($colours, gray, "secondary");
      }
    }
  }
}
