@use "@angular/material" as mat;
@use "sass:map";

mat-tab-header,
.mat-mdc-tab-header,
mat-tab-header.mat-tab-header {
  @extend %sticky; //Make it sticky using placeholder

  display: flex;
  top: multiply-two-numbers($size, 3.5);
  background-color: map-deep-get($colours, white, "primary");
  z-index: 2;
  box-shadow: 0 multiply-two-numbers($size, 0.2)
    multiply-two-numbers($size, 0.2) map-deep-get($colours, gray, "secondary");
}

.mat-mdc-tab-body-content {
  padding-top: multiply-two-numbers($size, 0.1) !important;
}

.mat-mdc-tab-body,
.mat-expansion-panel-content {
  padding: $size;

  @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
    padding: divide-two-numbers($size, 2);
  }
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-content,
div.mat-expansion-panel-content {
  padding-top: 0;
  padding-bottom: 0;
  font-size: $size;
}

.mat-drawer-content,
.mat-sidenav-container,
mat-sidenav-content,
mat-sidenav-container,
mat-sidenav-content.mat-drawer-content,
mat-sidenav-container.mat-sidenav-container,
.mat-drawer {
  overflow: visible !important;
}

mat-sidenav:not(.mat-drawer-opened) {
  & ~ mat-sidenav-content {
    mat-tab-body {
      @media (max-width: map-deep-get($breakpoints, "desktop", "max")) {
        margin-left: multiply-two-numbers($size, 2);
      }

      @media (max-width: map-deep-get($breakpoints, "tablet", "max")) {
        margin-left: inherit;
      }
    }
  }
}

app-calculator-name {
  @extend %sticky; //Make it sticky using placeholder

  z-index: 2;
}

mat-form-field,
.mat-mdc-form-field,
mat-form-field.mat-form-field {
  $matFormField: &;

  margin-top: multiply-two-numbers($size, 0.25);

  & + mat-icon,
  & + .mat-icon,
  & + mat-icon.mat-icon {
    margin-top: multiply-two-numbers($size, 1.25);
  }

  &.mat-form-field-disabled {
    @extend %disabled;

    opacity: 1;
  }
}

mat-hint,
.mat-mdc-form-field-hint,
mat-hint.mat-hint {
  font-size: $size;
  color: map-deep-get($colours, black, "primary");
}

.mdc-text-field--focused mat-label {
  font-size: $size !important;
  color: map-deep-get($colours, gray, "tertiary") !important;
}

mat-form-field label:has(> mat-label):not(.mdc-floating-label--float-above) {
  padding-right: multiply-two-numbers($size, 3) !important;
}

mat-accordion,
mat-accordion.mat-accordion,
.mat-accordion {
  margin-bottom: $size;
  display: block;

  mat-expansion-panel,
  mat-expansion-panel.mat-expansion-panel,
  .mat-expansion-panel {
    &:not(.mat-expanded) {
      border-bottom: none;

      &:last-child {
        border-bottom: multiply-two-numbers($size, 0.1) solid
          map-deep-get($colours, gray, "secondary");
      }
    }
  }
}

mat-expansion-panel,
mat-expansion-panel.mat-expansion-panel,
.mat-expansion-panel {
  margin: 0 !important;
  border: multiply-two-numbers($size, 0.1) solid
    map-deep-get($colours, gray, "secondary");

  &:only-of-type {
    margin-bottom: $size !important;
  }

  &.mat-expansion-panel-spacing {
    margin-top: $size !important;
    margin-bottom: $size !important;
  }

  mat-expansion-panel-header,
  mat-expansion-panel-header.mat-expansion-panel-header,
  .mat-expansion-panel-header {
    font-size: $size;
    height: auto !important;
    margin-bottom: 0;
    padding: 0 $size;
    width: 100% !important;
    background-color: map-deep-get($colours, gray, "primary");
    min-height: multiply-two-numbers($size, 4);

    &:hover {
      background-color: map-deep-get($colours, white, "primary") !important;
    }

    &.mat-expanded {
      margin-bottom: $size;
      background-color: map-deep-get($colours, white, "primary");
    }

    .mat-content {
      display: flex;

      .flex-child {
        margin-top: $size;
        width: 50%;

        &:last-child {
          padding-right: $size;
        }

        &.mat-icon {
          margin-top: divide-two-numbers($size, 2);
        }
      }
    }
  }

  mat-action-row,
  mat-action-row.mat-action-row,
  .mat-action-row {
    padding-right: 0;
    padding-left: 0;
    margin-top: $size;

    button {
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  mat-expansion-panel,
  mat-expansion-panel.mat-expansion-panel,
  .mat-expansion-panel {
    background-color: map-deep-get($colours, gray, "primary");

    mat-form-field:has(*[readonly="true"]) {
      pointer-events: none;

      label {
        pointer-events: none;
      }
    }

    mat-expansion-panel-header,
    mat-expansion-panel-header.mat-expansion-panel-header,
    .mat-expansion-panel-header {
      background-color: map-deep-get($colours, gray, "primary");

      &.mat-expanded {
        &:hover,
        &:focus {
          background-color: map-deep-get($colours, gray, "primary") !important;
        }
      }
    }

    .flex-child--side-bar {
      background-color: map-deep-get($colours, white, "primary");
      border: (multiply-two-numbers($size, 0.1)) solid
        map-deep-get($colours, gray, "secondary");
    }

    .mat-action-row {
      border-top-color: map-deep-get($colours, gray, "tertiary") !important;
    }

    legend {
      color: map-deep-get($colours, black, "primary");
    }
  }

  mat-form-field,
  mat-form-field.mat-mdc-form-field,
  .mat-form-field {
    &:active,
    &:focus,
    &:focus-within {
      label mat-label {
        background-color: map-deep-get($colours, white, "primary");
      }
    }

    &.ng-invalid,
    &.mat-form-field-invalid {
      label mat-label {
        background-color: map-deep-get($colours, white, "primary");
      }
    }
  }
}

.mat-mdc-dialog-container,
.mat-mdc-menu-panel {
  margin: 0 auto;
  min-width: calc(
    #{map-deep-get($breakpoints, "mobile", "min")} - #{multiply-two-numbers(
        $size,
        2
      )}
  ) !important;

  .mat-mdc-dialog-content {
    width: 100%;
    min-width: calc(
      #{map-deep-get($breakpoints, "mobile", "min")} - #{multiply-two-numbers(
          $size,
          4
        )}
    );
    max-width: 100%;
    position: relative;
    display: block;
    padding: 0 !important;
    margin: 0 !important;
    max-height: calc(100vh - #{multiply-two-numbers($size, 18)}) !important;

    p:only-child {
      margin-bottom: $size;
    }

    p:last-child {
      margin-bottom: $size !important;
    }

    ul {
      margin-bottom: $size;
    }

    padding-top: multiply-two-numbers($size, 0.1) !important;

    @media (max-height: map-deep-get($breakpoints, "mobile", "max")) {
      max-height: calc(100vh - #{multiply-two-numbers($size, 12)}) !important;
    }
  }

  .mat-mdc-dialog-surface {
    padding: 0 25px !important;
  }

  .mdc-dialog__title {
    padding: 0;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
    padding-top: $size !important;
    padding-bottom: 0 !important;
    border-top: multiply-two-numbers($size, 0.1) solid
      map-deep-get($colours, gray, "secondary");

    &.flex.flex--end-vertical {
      padding-top: 0 !important;

      &:first-child {
        button.button {
          margin-left: 0;
        }
      }

      &:last-child {
        button.button {
          margin-right: 0;
        }
      }

      button.button {
        margin-top: $size;
      }
    }
  }

  mat-form-field {
    margin-bottom: $size;
  }
}

.mat-mdc-menu-panel {
  padding: $size;
  max-width: 100% !important;
  width: min-content;

  .mat-mdc-menu-content {
    padding: 0 !important;

    &:not(:empty) {
      padding: inherit;
    }

    .mat-mdc-menu-item-text {
      margin: 10px 0 !important;
    }

    .mat-mdc-dialog-content {
      padding: 0 !important;
      max-height: none !important;
      width: min-content;
    }

    > .mat-focus-indicator {
      overflow: visible;
      white-space: normal;
      display: inline-block;
      line-height: inherit;
    }
  }

  .mat-mdc-dialog-actions {
    margin-bottom: 0;
  }
}

mat-icon,
mat-icon.mat-icon,
.mat-icon {
  font-size: multiply-two-numbers($size, 2) !important;
  vertical-align: middle;
  height: auto !important;
  width: auto !important;
  line-height: 1 !important;

  &.mat-mdc-tooltip-trigger {
    cursor: help;
  }

  &.flex-child {
    align-self: flex-start;
  }

  @media print {
    display: none;
  }
}

mat-radio-button,
mat-radio-button.mat-mdc-radio-button,
.mat-radio-button {
  padding-top: divide-two-numbers($size, 2);
  padding-bottom: divide-two-numbers($size, 2);

  button {
    margin-bottom: 0;
  }
}

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
  background-color: map-deep-get($colours, black, "transparancy");
}

.mat-mdc-tab-list {
  .mat-mdc-tab {
    min-width: 160px;
    border-right-width: multiply-two-numbers($size, 0.1);
    border-right-style: solid;
    border-right-color: map-deep-get($colours, black, "primary");
    border-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        map-deep-get($colours, black, "primary")
      )
      0 100%;

    &:last-of-type {
      border-right: none;
    }

    &:hover {
      background-color: map-deep-get($colours, gray, "primary");
      background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          map-deep-get($colours, gray, "primary")
        )
        0 100%;
    }
  }
}

mat-tab-group,
mat-tab-group.mat-mdc-tab-group,
.mat-tab-group {
  margin-bottom: 0 !important;
}

mat-tree-node,
mat-tree-node.mat-tree-node,
.mat-tree-node {
  font-size: $size;
  padding: divide-two-numbers($size, 2);

  &.highlight-color {
    font-weight: map.get($font-weights, "medium");
    background-color: map-deep-get($colours, gray, "primary");
  }
}

.mat-mdc-icon-button {
  line-height: 1 !important;
  height: auto !important;
  width: auto !important;

  &:not(mat-form-field .mat-mdc-icon-button) {
    padding: 0 !important;
  }
  // padding: 0 !important;
}

.mat-datepicker-content {
  mat-calendar,
  .mat-calendar,
  mat-calendar.mat-calendar {
    height: auto;
    padding-bottom: $size;

    .mat-calendar-controls {
      .mat-mdc-icon-button {
        height: multiply-two-numbers($size, 2.5) !important;
        width: multiply-two-numbers($size, 2.5) !important;
      }
    }
  }
}

.mat-datepicker-toggle {
  color: map-deep-get($colours, green, "primary") !important;

  .mat-mdc-icon-button {
    height: multiply-two-numbers($size, 2.5) !important;
    width: multiply-two-numbers($size, 2.5) !important;
    padding: 8px 0 !important;
  }
}

.mat-toolbar-row,
mat-toolbar-row.mat-toolbar-row,
.mat-toolbar-single-row {
  height: auto;
}

.mat-toolbar-multiple-rows,
mat-toolbar.mat-toolbar-multiple-rows {
  min-height: auto;
}

mat-error,
mat-error.mat-mdc-form-field-error,
.mat-error {
  font-size: $size;
  color: map-deep-get($colours, red, "primary");
}

.cdk-overlay-pane {
  max-width: calc(100vw - #{multiply-two-numbers($size, 2)}) !important;
  max-height: calc(100vh - #{multiply-two-numbers($size, 2)}) !important;

  &:not(:has(mat-option)) {
    width: auto !important;
    height: auto !important;
  }

  @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
    max-width: calc(100vw - #{$size}) !important;
    max-height: calc(100vh - #{multiply-two-numbers($size, 8)}) !important;
  }

  @media (max-height: map-deep-get($breakpoints, "mobile", "max")) {
    max-height: calc(100vh - #{multiply-two-numbers($size, 4)}) !important;
  }
}

.mat-drawer-container {
  position: unset !important;
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    line-height: multiply-two-numbers($size, 1.5) !important;
    height: auto !important;
    padding: $size;
    overflow: unset;
    /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    .mat-option-text {
      overflow: unset;
      display: block;
      white-space: normal;
      line-height: multiply-two-numbers($size, 1.5);
    }
  }
}

body {
  .mat-mdc-list-base .mat-mdc-list-item .mat-list-item-content-reverse,
  .mat-list-base .mat-list-option .mat-list-item-content-reverse,
  .mat-list-base .mat-list-item .mat-list-item-content,
  .mat-list-base .mat-list-option .mat-list-item-content {
    padding: 0;
  }

  .mat-mdc-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-prefix
    .mat-icon,
  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon {
    display: inline-block;
  }

  conversion-field .mat-mdc-form-field {
    display: inline;
  }
}

.mat-mdc-radio-group:not(.flex-radio-group) {
  margin: 0;

  mat-radio-button,
  mat-radio-button.mat-mdc-radio-button,
  .mat-radio-button {
    button {
      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.flex-radio-group.mat-mdc-radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: map-deep-get($breakpoints, "mobile", "max")) {
    flex-direction: column;
  }

  .mat-mdc-radio-button {
    margin-right: $size;
  }
}

.badge--red-primary {
  .mat-badge-content {
    @include badge(red, "primary");
  }
}
.badge--yellow-primary {
  .mat-badge-content {
    @include badge(yellow, "primary");
  }
}

.badge--blue-primary {
  .mat-badge-content {
    @include badge(blue, "primary");
  }
}

.mat-mdc-tooltip {
  white-space: pre-wrap;
}

// Override the default theming color for dialog supporting text
.mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: map-deep-get($colours, black, "primary");
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  --mat-option-selected-state-layer-color: map-deep-get(
    $colours,
    black,
    "border"
  ) !important;
}

// style checkbox margins for the report options
*[id$="report-options"] {
  mat-radio-button > .mdc-form-field {
    .mdc-radio {
      padding-left: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .mdc-checkbox {
    margin-left: -11px;
  }
}

app-filter-menu,
.mat-mdc-dialog-actions .mdc-button__label {
  letter-spacing: multiply-two-numbers($size, negate-number(0.015)) !important;
}

// style tooltip width
.mdc-tooltip__surface {
  max-width: 250px !important;
  line-height: 20px !important;
}

.multiline-tooltip {
  white-space: pre-wrap;
  max-width: unset !important;

  .mdc-tooltip__surface {
    max-width: unset !important;
  }
}

// default fields have no margins on bottom
mat-form-field {
  margin-bottom: $size;

  &:hover {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-width: 2px !important;
    }
  }
}

// default fields have transparent backgrounds; set to white (against grey)
mat-form-field:not(.disabled) {
  .mdc-text-field:not(:has(*[readonly="true"])) {
    background-color: white;
  }
}

/* ------------ Keep ------------ */
/* TO DO: Imported from _theme.scss, revise */

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$default-app-primary: mat.m2-define-palette($mat-black-custom);
$default-app-accent: mat.m2-define-palette($mat-green-custom, 900, A200, A700);

// The warn palette is optional (defaults to red).
$default-app-warn: mat.m2-define-palette(mat.$m2-red-palette, 700, 300, 900);

// Create the theme object (a Sass map containing all of the palettes).
$default-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $default-app-primary,
      accent: $default-app-accent,
      warn: $default-app-warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $button:
          mat.m2-define-typography-level(
            $font-size: $size,
            $line-height: 1.4,
            $font-weight: map.get($font-weights, "medium"),
          ),
        $caption:
          mat.m2-define-typography-level(
            $font-size: $size,
            $line-height: 1.4,
            $font-weight: map.get($font-weights, "normal"),
            $letter-spacing: multiply-two-numbers($size, negate-number(0.015)),
          ),
      ),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($default-app-theme);

// style the tooltip
$tt-typography: mat.m2-define-typography-config(
  $caption:
    mat.m2-define-typography-level(
      $font-size: $size,
      $line-height: 1.4,
      $font-weight: map.get($font-weights, "normal"),
    ),
);

// body-1 is used for dialog titles
$dialog-typography: mat.m2-define-typography-config(
  $headline-6:
    mat.m2-define-typography-level(
      $font-size: $size,
      $line-height: 1,
      $font-weight: map.get($font-weights, "medium"),
    ),
  $body-1:
    mat.m2-define-typography-level(
      $font-size: $size,
      $line-height: 1.4,
      $font-weight: map.get($font-weights, "normal"),
      $letter-spacing: multiply-two-numbers($size, negate-number(0.015)),
    ),
);

// body-2 is used for radio button labels
$radio-button-typography: mat.m2-define-typography-config(
  $body-2:
    mat.m2-define-typography-level(
      $font-size: $size,
      $line-height: 1,
    ),
);

$checkbox-typography: mat.m2-define-typography-config(
  $body-2:
    mat.m2-define-typography-level(
      $font-size: $size,
      $line-height: 1.4,
    ),
);

$button-typography: mat.m2-define-typography-config(
  $body-1:
    mat.m2-define-typography-level(
      $font-size: $size,
      $line-height: 1.4,
    ),
);

$stepper-typography: mat.m2-define-typography-config(
  $body-2:
    mat.m2-define-typography-level(
      $font-size: $size,
      $line-height: 1.4,
      $font-weight: map.get($font-weights, "normal"),
    ),
  $body-1:
    mat.m2-define-typography-level(
      $font-size: $size,
      $line-height: 1.4,
      $font-weight: map.get($font-weights, "medium"),
    ),
);

@include mat.tooltip-typography($tt-typography);
@include mat.dialog-typography($dialog-typography);

// radio button theme
@include mat.radio-typography($radio-button-typography);
@include mat.radio-density(-5);

// checkbox theme
@include mat.checkbox-typography($checkbox-typography);
@include mat.checkbox-density(-5);

@include mat.menu-typography($button-typography);
@include mat.stepper-typography($stepper-typography);
